import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Typography,
  Box,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import axios from 'axios';
import { useParams , useLocation , useNavigate} from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { ORG_FETCH_PARTICULAR_REVIEW, ORG_UPDATERATING } from '../../api/org';
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const OrgBookings = () => {
  const { id } = useParams();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);
  const [formData, setFormData] = useState({});
  const [screenshot, setScreenshot] = useState(null);
  const [file, setFile] = useState(null);
  const location = useLocation();
  const orgDetails = location?.state?.org || {};
  const navigate = useNavigate();
  // Load saved form data from localStorage on component mount
  useEffect(() => {
    const savedData = localStorage.getItem('orgBookingsFormData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  // Save to localStorage whenever formData changes
  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      localStorage.setItem('orgBookingsFormData', JSON.stringify(formData));
    }
  }, [formData]);

  // Fetch bookings on component mount
  useEffect(() => {
    axios
      .get(ORG_FETCH_PARTICULAR_REVIEW + id)
      .then((response) => {
        const sortedBookings = response.data.data.sort((a, b) => {
          const dateTimeA = dayjs(`${a.bookingDate} ${a.time}`, 'YYYY-MM-DD h:mm A');
          const dateTimeB = dayjs(`${b.bookingDate} ${b.time}`, 'YYYY-MM-DD h:mm A');
          return dateTimeA.diff(dateTimeB);
        });
        setBookings(sortedBookings);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching bookings:', error);
        setLoading(false);
      });
  }, [id]);

  // Handle dialog open
  const handleDialogOpen = (booking) => {
    setCurrentBooking(booking);
    const savedData = localStorage.getItem('orgBookingsFormData');
    const savedScreenshots = localStorage.getItem('orgBookingsScreenshots');
    
    if (savedScreenshots) {
      const screenshots = JSON.parse(savedScreenshots);
      setScreenshot(screenshots[booking._id] || null);
    }
    
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData(prevData => ({
        ...prevData,
        [booking._id]: parsedData[booking._id] || {}
      }));
    }
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentBooking(null);
    setFile(null);
  };

  const handleInputChange = (name, value) => {
    setFormData((prev) => {
      const newData = {
        ...prev,
        [currentBooking._id]: {
          ...prev[currentBooking._id],
          [name]: value,
        },
      };
      localStorage.setItem('orgBookingsFormData', JSON.stringify(newData));
      return newData;
    });
  };

  const handleScreenshotUpload = (file) => {
    const reader = new FileReader();
    setFile(file);
    reader.onload = () => {
      setScreenshot(reader.result);
      // Save screenshot to localStorage
      const savedScreenshots = localStorage.getItem('orgBookingsScreenshots');
      const screenshots = savedScreenshots ? JSON.parse(savedScreenshots) : {};
      screenshots[currentBooking._id] = reader.result;
      localStorage.setItem('orgBookingsScreenshots', JSON.stringify(screenshots));
    };
    reader.readAsDataURL(file);
    handleInputChange('screenshot', file);
  };

  const handlePasteScreenshot = (e) => {
    const clipboardData = e.clipboardData;
    const file = clipboardData.files[0];
    if (file && file.type.startsWith('image/')) {
      handleScreenshotUpload(file);
    }
  };

  const handleSubmit = () => {
    const dataToSubmit = formData[currentBooking._id] || {};
    const formDataToSend = new FormData();

    for (const [key, value] of Object.entries(dataToSubmit)) {
      formDataToSend.append(key, value);
    }

    if (screenshot) {
      formDataToSend.append('screenshot', file);
    }

    axios
      .put(`${ORG_UPDATERATING}${currentBooking._id}/rating/final`, formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        setBookings((prev) =>
          prev.map((booking) =>
            booking._id === currentBooking._id ? { ...booking, ...response.data } : booking
          )
        );
        handleDialogClose();
      })
      .catch((error) => {
        console.error('Error submitting form data:', error);
      });
  };

  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'whatsappNumber', headerName: 'WhatsApp', width: 100 },
    { field: 'studentCode', headerName: 'Student Code', width: 80 },
    {
      field: 'bookingDate',
      headerName: 'Booking Date',
      width: 150,
      valueGetter: (params) =>
        params.row.bookingDate
          ? dayjs(params.row.bookingDate).format('DD MMM YYYY')
          : 'Not Booked',
    },
    { field: 'time', headerName: 'Booking Time', width: 100 },
    {
      field: 'form',
      headerName: 'Form',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDialogOpen(params.row)}
          sx={{
            background: params.row.ratingOverall && 'orange'
          }}
        >
          {params.row.ratingOverall ? 'Edit Review' : 'Add Review'}
        </Button>
      ),
    },
  ];
 
  return (
    <Container sx={{ width: '100%' }} onPaste={handlePasteScreenshot}>
      <Box sx={{ mt: 4, width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          Organization Bookings
        </Typography>

        <Box sx={{ mb: 3 }}>
          {/* <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Organization Email:</strong> {orgDetails.email || 'N/A'}
          </Typography> */}
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Organization ID:</strong> {orgDetails.orgCode || 'N/A'}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Organization Name:</strong> {orgDetails.name || 'N/A'}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Email:</strong> {orgDetails.email || 'N/A'}
          </Typography>
        </Box>


        <Box sx={{ height: '90vh', width: '100%' }}>
          <DataGrid
            rows={bookings}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row._id}
            loading={loading}
          />
        </Box>
      </Box>

      {/* Edit/Add Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{currentBooking?.ratingOverall ? 'Edit Review' : 'Add Review'}</DialogTitle>
        <DialogContent>
          {[
            { name: 'ratingOverall', label: 'Rating Overall' },
            { name: 'communicationRating', label: 'Communication Rating' },
            { name: 'technicalSkillsetRating', label: 'Technical Skillset Rating' },
            { name: 'experienceRating', label: 'Experience Rating' },
            { name: 'trainingRating', label: 'Training Rating' },
            { name: 'knowledgeRating', label: 'Knowledge Rating' },
          ].map((ratingField) => (
            <Box key={ratingField.name} mt={2}>
              <FormControl component="fieldset">
                <FormLabel>{ratingField.label}</FormLabel>
                <RadioGroup
                  row
                  value={
                    formData[currentBooking?._id]?.[ratingField.name] ||
                    currentBooking?.[ratingField.name] ||
                    ''
                  }
                  onChange={(e) => handleInputChange(ratingField.name, e.target.value)}
                >
                  {[...Array(11).keys()].map((value) => (
                    <FormControlLabel
                      key={value}
                      value={value.toString()}
                      control={<Radio />}
                      label={value}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          ))}

          <Box mt={3}>
            <Typography>Feedback:</Typography>
            <ReactQuill
              value={
                formData[currentBooking?._id]?.feedback || currentBooking?.feedback || ''
              }
              onChange={(value) => handleInputChange('feedback', value)}
              placeholder="Write your feedback..."
            />
          </Box>
          <Box mt={3}>
            <Typography>Suggestion:</Typography>
            <ReactQuill
              value={formData[currentBooking?._id]?.suggestion || currentBooking?.suggestion || ''}
              onChange={(value) => handleInputChange('suggestion', value)}
              placeholder="Write your suggestion..."
            />
          </Box>
          <Box mt={3}>
            <Typography>Strength:</Typography>
            <ReactQuill
              value={formData[currentBooking?._id]?.strength || currentBooking?.strength || ''}
              onChange={(value) => handleInputChange('strength', value)}
              placeholder="Write the student's strengths..."
            />
          </Box>
          <Box mt={3}>
            <Typography>Weakness:</Typography>
            <ReactQuill
              value={formData[currentBooking?._id]?.weakness || currentBooking?.weakness || ''}
              onChange={(value) => handleInputChange('weakness', value)}
              placeholder="Write the student's weaknesses..."
            />
          </Box>

          <Box
            sx={{
              mt: 2,
              p: 2,
              border: '2px dashed #ccc',
              borderRadius: '8px',
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: '#f9f9f9',
            }}
            onDrop={(e) => {
              e.preventDefault();
              const file = e.dataTransfer.files[0];
              if (file) handleScreenshotUpload(file);
            }}
            onDragOver={(e) => e.preventDefault()}
          >
            {screenshot ? (
              <img
                src={screenshot}
                alt="Screenshot Preview"
                style={{ maxWidth: '100%', maxHeight: 200 }}
              />
            ) : (
              <Typography>Paste, Drag & Drop, or Click to Upload Screenshot</Typography>
            )}
          </Box>

          <Button variant="contained" component="label" fullWidth sx={{ mt: 2 }}>
            Upload Screenshot
            <input
              type="file"
              hidden
              onChange={(e) => handleScreenshotUpload(e.target.files[0])}
            />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OrgBookings;