import "./index.css";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Axios from "axios";
import MessagePopup from "../MessagePopup";
import { Typography, Link, Button } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { SettingsBluetoothRounded } from "@mui/icons-material";

const tags = [
  {
    value: 'VSCLANDING',
    label: 'VSC Landing',
  },
  {
    value: 'MARKOKNOW',
    label: 'MARKOKNOW',
  },
  {
    value: 'VSCDASHBOARD',
    label: 'VSC NOTIFICATION DASHBOARD',
  },
  {
    value: 'VSCMAINDASHBOARD',
    label: 'VSC MAIN DASHBOARD',
  },
  {
    value: 'VSCEVENTS',
    label: 'VSC EVENTS',
  },
  {
    value: 'SD',
    label: 'STUDENT DASHBOARD',
  }
];

const {
  NEW_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  GET_ANNOUNCEMENTS,
  GET_SINGLE
} = require("../../api/announcement");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 44,
  p: 6,
  align: "center",
};

const Announcement = ({ isAuth, a_announce }) => {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [resp, setResp] = useState("");

  
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);

  const [boldhead, setBoldHead] = useState("");
  const [anndetails, setAnnDetails] = useState("");
  const [annlink, setAnnLink] = useState("");
  const [selectedTag, setSelectedTag] = useState('');

  const [Uboldhead, setUBoldHead] = useState("");
  const [Uanndetails, setUAnnDetails] = useState("");
  const [Uannlink, setUAnnLink] = useState("");
  const [UselectedTag , setUSelectedTag] = useState("");

  const [toUpdate, setToUpdate] = useState(null);
  const [newAnnouncement, setNewAnnouncement] = useState([]);
  const [added , setAdded] = useState(false);
  const handleOpen = () => {
    Axios.get(GET_SINGLE+toUpdate)
      .then((response) => {
        setUBoldHead(response.data.title);
        setUAnnLink(response.data.link);
        setUAnnDetails(response.data.body);
        setUSelectedTag(response.data.tag);
        setOpen(true)
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
      });
    
  };
  useEffect(() => {
    Axios.get(GET_ANNOUNCEMENTS, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        setNewAnnouncement(response.data);
        setAdded(false);
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
      });
  }, [added]);

  const active_announcement = () => {
    if (boldhead === "" || anndetails === "" || annlink === "" || selectedTag === "") {
      setResp("Kindly add all the details");
      setOpenPopUp(true);
    } else {
      const heading = boldhead;
      const details = anndetails;
      const link = annlink;
      const selectedT = selectedTag
      // setNewAnnouncement([...newAnnouncement,newobj]);
      Axios.post(
        NEW_ANNOUNCEMENT,
        {
          heading: heading,
          details: details,
          link: link,
          tag: selectedT
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {
          setResp("Created Successfully");
          setAdded(true);
          setOpenPopUp(true);
        })
        .catch((err) => {
          setResp("Something error occured");
          setOpenPopUp(true);
        });

      setBoldHead("");
      setAnnDetails("");
      setAnnLink("");
      setSelectedTag("");
    }
  };

  const update_announcement = () => {
    const heading = Uboldhead;
    const details = Uanndetails;
    const link = Uannlink;
    const id = toUpdate;
    const selectedT = UselectedTag;
    Axios.patch(
      UPDATE_ANNOUNCEMENT+id,
      {
        heading: heading,
        details: details,
        link: link,
        tag: UselectedTag
      },
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        setResp("Edited Successfully");
        setAdded(true);
        setOpenPopUp(true);
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });

    setUBoldHead("");
    setUAnnDetails("");
    setUAnnLink("");
    setSelectedTag("");
    setUSelectedTag("");
  };
  const DeleteRec = (todeleteid) => {
    Axios.delete(DELETE_ANNOUNCEMENT + todeleteid, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setResp("Deleted Successfully");
        setAdded(true);
        setOpenPopUp(true);
      })
      .catch((err) => {
        setResp("Something error occured");
        setOpenPopUp(true);
      });
  };
  const handleChange = (event) => {
    setSelectedTag(event.target.value);
    setUSelectedTag(event.target.value);
  };

  return (
    <>
      {a_announce ? (
        <>
          <div className="announcement_panel">
            <div className="create_announcement">
              <p id="announcments_heads">Create Announcement</p>
              <input
                type="text"
                value={boldhead}
                placeholder="Bold Head"
                onChange={(e) => {
                  setBoldHead(e.target.value);
                }}
              />
              <input
                type="text"
                value={anndetails}
                placeholder="Announcement"
                onChange={(e) => {
                  setAnnDetails(e.target.value);
                }}
              />
              <input
                type="text"
                value={annlink}
                placeholder="link"
                onChange={(e) => {
                  setAnnLink(e.target.value);
                }}
              />
                  <select
            id="tag-select"
            value={selectedTag}
            onChange={handleChange}
            style={{
              backgroundColor: '#fff',
              color: 'black',
              height: '40px',
              textAlign: 'center',
              marginTop: '5%',
            }}
          >
            <option value="" disabled>Select Tag</option>
            {tags.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
              <button
                id="submit_ann"
                type="submit"
                onClick={() => {
                  active_announcement();
                }}
              >
                Create
              </button>
            </div>

            <div className="show_announcement">
              <span id="announcments_head_2">Active Announcement</span>
              {newAnnouncement.map((val, key) => {
                return (
                  <div key={val._id}>
                    <div className="created_announcement">
                      <p>
                        {key+1}.{" "}<span id="heading_announcement">{val.title}</span>
                        {"  "}
                        {val.body}
                      </p>
                     
                      <span id="edit_icon">
                        <EditIcon
                          onClick={() => {
                            handleOpen();
                            setToUpdate(val._id);
                          }}
                        />
                        <DeleteIcon
                          onClick={() => {
                            DeleteRec(val._id);
                          }}
                        />
                      </span>
                    </div>
                    <p align="left">
                       Place : {" "} {val.tag}
                      </p>
                    <p id="heading_announcement_link"> Link : {" "}{val.link}</p>
                    
                  </div>
                );
              })}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="update_announcement">
                    <strong id="announcments_heads_3">
                      Update Announcement
                    </strong>
                    <input
                      type="text"
                      value={Uboldhead}
                      placeholder="Bold Head"
                      onChange={(e) => {
                        setUBoldHead(e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      value={Uanndetails}
                      placeholder="Announcement"
                      onChange={(e) => {
                        setUAnnDetails(e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      value={Uannlink}
                      placeholder="link"
                      onChange={(e) => {
                        setUAnnLink(e.target.value);
                      }}
                    />
                    <select
                      id="tag-select"
                      value={UselectedTag}
                      onChange={handleChange}
                      style={{
                        backgroundColor: '#fff',
                        color: 'black',
                        height: '40px',
                        textAlign: 'center',
                        marginTop: '5%',
                      }}
                    >
                      <option value="" disabled>Select Tag</option>
                      {tags.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <button
                      id="submit_ann"
                      type="submit"
                      onClick={() => {
                        update_announcement();
                        handleClose();
                      }}
                    >
                      Update
                    </button>
                  </div>
                </Box>
              </Modal>
            </div>
            <MessagePopup
              open={openPopUp}
              message={resp}
              handleAlertClose={() => setOpenPopUp(!openPopUp)}
            />
          </div>
        </>
      ) : (
        <Box>
          <Typography>You are not allowed</Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button>Go back</Button>
          </Link>
        </Box>
      )}
    </>
  );
};
export default Announcement;
