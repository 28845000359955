import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Collapse from '@mui/material/Collapse';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListItemText from '@mui/material/ListItemText';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UploadDialog from '../UploadDialog';
import ss from './bmc.png'
import AddIcon from '@mui/icons-material/Add';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import axios from 'axios';
import ModuleUploadDialog from '../ModuleUploadDialog';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import VideoPlayer from '../VideoPlayer/index';
import Modal from '@material-ui/core/Modal';
import { TextField } from '@mui/material';
import { POST_COURSE , GET_MODULES , UPDATE_COURSE , DELETE_MODULE , GET_VIDEO , GET_PDF , DELETE_VIDEO , DELETE_PDF, GET_QUESTIONS, DELETE_QUESTIONS, GET_PROJECTS , POST_PRIORITY_VIDEO, GET_SINGLE_VIDEO, GET_SINGLE_PDF, POST_PRIORITY_PDF, GET_SINGLE_MODULE, POST_PRIORITY_MODULE , UPDATE_VIDEO_MODULE, UPDATE_PDF_MODULE} from '../../../api/courseupload';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import MessagePopup from "../../MessagePopup";
import AssignmentIcon from '@mui/icons-material/Assignment';
import JoditEditor from 'jodit-react';
import ProjectUpload from '../ProjectUpload.jsx';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import UpdateModuleDialog from '../UpdateModuleDialog';
import {FormControlLabel , Checkbox} from '@mui/material';
const drawerWidth = 700;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: open ? '0' : `-${drawerWidth}px`,
  width: open ? '100%' : `calc(100% + ${drawerWidth}px)`,
  
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    })
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function SideDrawer({btn  , cN , courseIndex , courseId , cP , handleUpdate , handleAdd , p , outcome , l , m , f , ts , t , tp}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openM, setOpenM] = React.useState(false);
  const [videoFullScreen,setVideoFullscreen] = React.useState(false);
  const [openHeader, setOpenHeader] = React.useState(null);
  const [file , setFile] = React.useState(ss);
  const [userPreview , setUserPreview] = React.useState('');
  const [courseData, setCourseData] = React.useState([]);
  const [heading , setHeading] = React.useState('');
  const [openVideo, setOpenVideo] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');
  const [op , setOp] = React.useState(false);
  const [openPDF, setOpenPDF] = React.useState(false);
  const [openQuestions, setOpenQuestions] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState('');
  const [module , setModule] = React.useState(null);
  const [moduleId , setModuleId] = React.useState(null);
  const [modules, setModules] = React.useState([]);
  const [currentModuleIndex, setCurrentModuleIndex] = React.useState(null);
  const [courseName , setCourseName] = React.useState(cN || '');
  const [coursePreview , setCoursePreview] = React.useState(ss);
  const [price , setPrice] = React.useState(p||'')
  const [videos , setVideos] = React.useState([]);
  const [pdfs , setPdfs] = React.useState([]);
  const [fetched , setFetched] = React.useState(false);
  const [video, setVideoId] = React.useState('');
  const [pdfId, setPdfId] = React.useState('');
  const [resp, setResp] = React.useState("");
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [added, setAdded] = React.useState(false);
  const [courseNew , setCourseNew] = React.useState('');
    const [questions, setQuestions] = React.useState([]);
    const [outcomes , setOutcomes] = React.useState(outcome||'');
    const [learn , setLearn] = React.useState(l|| "");
    const [material , setMaterial] = React.useState(m || "");
    const [qa , setqa] = React.useState([]);
  const [openProject  , setOpenProject ] = React.useState(false);
  const editor = React.useRef(null);
  const [editorContent, setEditorContent] = React.useState('');
  const [projects , setProjects] = React.useState([]);
  const [priority , setPriority] = React.useState(1000);
  const [pr, setP] = React.useState(1000);
  const [fw , setFw] = React.useState(f||'');
  const [moduleF , setModuleF] = React.useState(false);
  const [moduleSelect , setModulesSelect] = React.useState([]);
  const [tags , setTags] = React.useState(ts||[]);
  const [training , setTraining] = React.useState(t|false);
  const [trainingPrice , setTrainingPrice] = React.useState(tp||0)
  const [comingSoon , setComingSoon] = React.useState(false);

  const handleTagChange = (event) => {
    setTags([...tags , event]);
  };

  const fetchModules = ()=> {
   
      if (courseId) {
        axios
          .get(GET_MODULES + `/${courseId}`)
          .then((moduledata) => {
            const sortedModules = moduledata.data.message.sort((a, b) => {
              const priorityA = a.priority || 1000; 
              const priorityB = b.priority || 1000; 
              return priorityA - priorityB;
            });
            setModules(sortedModules);
            const newData = sortedModules.map(module => ({
              value: module._id,
              label: module.title
            }));
            setModulesSelect(newData);
            setAdded(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
  
  }
  const fetchProjects = ()=> {
   
    if (courseId) {
      axios
        .get(GET_PROJECTS + `${courseId}`)
        .then((projectdata) => {
          setProjects(projectdata.data.message);
          setAdded(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

}
  React.useEffect(()=> {
    fetchModules();
    if(moduleF){
    fetchVideos();
    fetchPdfs();
    fetchQuestions();
    setFetched(false);
    setModuleF(false);
    }
  },[moduleF,fetched,added])
  React.useEffect(()=> {
    if(courseId){
     fetchProjects()
    setFetched(false);
    }
    
  },[courseId , courseNew , added]);
  const fetchVideos = () => {
    axios.get(GET_VIDEO + moduleId)
    .then(data => {
      const sortedVideos = data.data.data.sort((a, b) => {
        const priorityA = a.priority || 1000; 
        const priorityB = b.priority || 1000; 
        return priorityA - priorityB;
      });
      setVideos(sortedVideos)
      setFetched(true);
    })
    .catch(err => {
      console.log(err);
    })
}
const fetchQuestions = () => {
  axios.get(GET_QUESTIONS + moduleId)
  .then(data => {
    setQuestions(data.data.data)
    setFetched(true);
  })
  .catch(err => {
    console.log(err);
  })
}
const fetchPdfs = async() => {
  await axios.get(GET_PDF + moduleId)
  .then(data => {
    const sortedPdfs = data.data.data.sort((a, b) => {
      const priorityA = a.priority || 1000; 
      const priorityB = b.priority || 1000; 
      return priorityA - priorityB;
    });
    setPdfs(sortedPdfs)
    setFetched(true);
  })
  .catch(err => {
    console.log(err);
  })
}

  const handleOpenPDF = (url) => {
    setPdfUrl(url);
    setOpenPDF(true);
  };
  const handleOpenQuestion = (questions) => {
    setqa(questions);
    setOpenQuestions(true);
  };

  const handleClosePDF = () => {
    setOpenPDF(false);
    setPdfUrl('');
  };

  

  const handleDrawerOpen = () => {
    setOpen(true);
    setVideoFullscreen(false);
    setCurrentModuleIndex(null);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
    setVideoFullscreen(true);
    setCurrentModuleIndex(null);
  };
  const handleUpload = (e) => {
      setCoursePreview(e.target.files[0]);
      setFile(URL.createObjectURL(e.target.files[0]));
  }
  const handleAddCourseData = (data) => {
    setCourseData(data);
  };
  const resetAll = () => {
    setCourseName('');
    setPrice('');
    setCoursePreview(ss);
  }
  const handleDeleteVideo = (id) => {
      axios
      .delete(DELETE_VIDEO+id)
      .then(response => {
        setResp("Deleted Video Successfully");
        setOpenPopUp(true);
      })
      .catch(err => {
        console.log(err);
      })
  }
  const handleDeletePdf = (id) => {
    axios
    .delete(DELETE_PDF+id)
    .then(response => {
      setResp("Deleted Pdf Successfully");
        setOpenPopUp(true);
    })
    .catch(err => {
      console.log(err);
    })
}
const handleDeleteQuestions = (id) => {
  axios
  .delete(DELETE_QUESTIONS+id)
  .then(response => {
    setResp("Deleted Questions Successfully");
      setOpenPopUp(true);
  })
  .catch(err => {
    console.log(err);
  })
}
const handleSaveCourse = (e) => {
  if(!courseId){
  const newformData = new FormData();
  newformData.append('courseName' , 'New Course');
  axios
  .post(POST_COURSE, newformData)
  .then(response => {
    setResp("Added Course Successfully");
    setOpenPopUp(true);
    setCourseNew(response.data.data._id)
    handleAdd(true);
  })
  .catch(err => {
    console.log(err);
  })
 }

}
  
  const handleSave = (e) => {
  if(courseId || courseNew){
  const updatedformData = new FormData();
  updatedformData.append('courseName' , courseName);
  updatedformData.append('price' , price);
  updatedformData.append('coursePreview' , coursePreview);
  updatedformData.append('outcomes' , outcomes);
  updatedformData.append('material' , material);
  updatedformData.append('learn' , learn);
  updatedformData.append('forWhom' , fw);
  updatedformData.append('tags' , tags);
  updatedformData.append('trainingEnabled' , training);
  updatedformData.append('trainingPrice' , trainingPrice);
  updatedformData.append('comingsoon' , comingSoon);
  const add = btn === 'edit' ? courseId : courseNew;
  axios
  .patch(UPDATE_COURSE+add, updatedformData)
  .then(response => {
    setResp("Updated Course Successfully");
    setOpenPopUp(true);
    handleUpdate(true);
    btn === 'add' && resetAll();
  })
  .catch(err => {
    console.log(err);
  })
 }
 else {
  const newformData = new FormData();
  newformData.append('courseName' , courseName);
  newformData.append('price' , price);
  newformData.append('coursePreview' , coursePreview);
  newformData.append('outcomes' , outcomes);
  newformData.append('material' , material);
  newformData.append('learn' , learn);
  newformData.append('forWhom' , fw);
  newformData.append('tags' , tags);
  newformData.append('trainingEnabled' , training);
  newformData.append('trainingPrice' , trainingPrice);
  newformData.append('comingsoon' , comingSoon);
  axios
  .post(POST_COURSE, newformData)
  .then(response => {
    setResp("Added Course Successfully");
    setOpenPopUp(true);
    handleUpdate(true);
  })
  .catch(err => {
    setResp("Some Error");
    setOpenPopUp(true);
  })
 }
  
  
  

    
    handleDrawerClose();
  }
  const handleRenderAdd = (value) => {
    setAdded(value);
  }
  const handleDeleteModule = (index) => {
    axios
    .delete(DELETE_MODULE+index)
    .then(data => {
      setResp("Deleted Module Successfully");
        setOpenPopUp(true);
    })
    .catch(err => {
      console.log(err);
    })

  }
  const handleCloseDialog = () => {
    setOp(false);
    setVideoId('');
    };
    const handleOpenDialogM = () => {
      setOpenM(true);
      // setVideoId('');
      };
      const handleCloseDialogM = () => {
        setOpenM(false);
        };
    const handleSetPriority = () => {
      axios
        .post(video ? POST_PRIORITY_VIDEO+video : POST_PRIORITY_PDF+pdfId  , {priority : priority})
        .then(response=> {
          setResp("Priority Is Set");
          setOpenPopUp(true);
          handleCloseDialog();
          video('');
          pdfId('');
        })
        .catch(err => {
          console.log(err);
        })
   }
   const handleSetPriorityM = () => {
    axios
      .post(POST_PRIORITY_MODULE+moduleId, {priority : pr})
      .then(response=> {
        setResp("Priority Is Set");
        setOpenPopUp(true);
        handleCloseDialogM();
        video('');
        pdfId('');
      })
      .catch(err => {
        console.log(err);
      })
    }
 const handleModuleChange = (event , videoId) => {
  const selectedModuleId = event.target.value;
  axios.patch(UPDATE_VIDEO_MODULE+videoId+"/"+selectedModuleId).then(res=> {}).catch(err => console.log(err));
};
const handleModuleChangePDF = (event , pdfId) => {
  const selectedModuleId = event.target.value;
  axios.patch(UPDATE_PDF_MODULE+pdfId+"/"+selectedModuleId).then(res=> {}).catch(err => console.log(err));
};
const currencies = [
  {
    value: 'Trending Skills',
    label: 'Trending Skills',
  },
  {
    value: 'AI Ready',
    label: 'AI Ready',
  },
  {
    value: 'High Packages',
    label: 'High Packages',
  },
  {
    value: 'Non Coder Job Friendly',
    label: 'Non Coder Job Friendly',
  },
  {
    value: 'Upcoming Hot Skills',
    label: 'Upcoming Hot Skills',
  },
];

  return (
    <Box sx={{ display: 'flex'}}>
      <CssBaseline />
      <AppBar position="movable" open={open} style={{ backgroundColor:'#F7F7F7' }}>
      {btn==='add'?
        <Toolbar style={{ width: '100%' , display: 'flex' , flexDirection: 'row' , justifyContent:'space-between' }}> 
         
            <Button 
             variant='outlined'
             onClick={()=> {
              handleSaveCourse();
              handleDrawerOpen();
              }}
             edge="start"
             sx={{ mr: 2,color:'#6A58C6',borderColor:'#6A58C6' ,...(open && { display: 'none' }) }}
             >Add New Course
             </Button>
          
             <h1 style={{color:'#000'}}>Course Upload Admin Panel</h1>
          
        </Toolbar>
      : <Button 
             variant='outlined'
             onClick={(e)=>{ 
              fetchModules();
              handleDrawerOpen();
             }}
             edge="start"
            //  sx={{ mr: 2,color:'#6A58C6',borderColor:'#6A58C6' ,...(open && { display: 'none' }) }}
             >Edit
             </Button>}
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            marginTop:'0'
          },
        }}
        variant="persistant"
        anchor="left"
        open={open}
      >
        <List style={{display: 'flex' , flexDirection: 'column' , justifyContent:'space-between' , marginTop:'0'}}>
          <DrawerHeader style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff' }}>
            <IconButton>
              <KeyboardBackspaceIcon fontSize="small" />

              <p style={{ fontSize: '10px', color: '#000' , marginLeft:'10px'}}>{cN}</p>
            </IconButton>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
            </IconButton>
            
          </DrawerHeader>
          
            <ListItem disablePadding style={{ outerHeight: '100px', innerHeight: '100px'  }}>
              <div style={{ display: 'flex', flexDirection: 'column', padding:"18px", backgroundColor: '#F0F0F0' , justifyContent:'space-between' , width:'100%' }}>
                 <img src= {btn === 'edit' ? cP : file } style={{width: '100%' , marginBottom: '10px'}}/>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Button
                variant="outlined"
                component="label"
                style={{color:'#000' , fontSize:'10px',borderColor:'#6A58C6'}}
                size="small"
                
                >
               Set User Preview
                <input
                type="file"
                hidden
       
                onChange={handleUpload}
                accept = '.png , .jpg , .jpeg'
                />
                </Button>
                 <Button 
                 variant='outlined'
                 style={{color:'#000' ,fontSize:'10px',borderColor:'#6A58C6'}}
                 >
                    Set Rules
                 </Button>
                </div>
              </div>
            </ListItem>
        
        </List>
        <Box>
        {currencies.map((currency) => (
        <FormControlLabel
          key={currency.value}
          control={
            <Checkbox
              checked={tags.includes(currency.value)}
              onChange={() => handleTagChange(currency.value)}
              name={currency.value}
            />
          }
          label={currency.label}
        />
      ))}
    </Box>
    <Box>
     <FormControlLabel
          key={'Enable Training'}
          control={
            <Checkbox
              checked={training}
              onChange={() => setTraining(!training)}
              name={training}
            />
          }
          label={'Enable Training'}
        />
        <FormControlLabel
          key={'Coming Soon'}
          control={
            <Checkbox
              checked={comingSoon}
              onChange={() => setComingSoon(!comingSoon)}
              name={comingSoon}
            />
          }
          label={'Coming Soon'}
        />
    </Box>
        <TextField
          id="standard-basic" 
          label="Course Name"
          variant="standard"
          style={{marginBottom:'10px'}}
          value={courseName}
          onChange={(e)=> setCourseName(e.target.value)}
        />
         <TextField
          id="standard-basic" 
          label="Price in Rupees"
          variant="standard"
          style={{marginBottom:'10px'}}
          value={price}
          onChange={(e)=> setPrice(e.target.value)}
        />
        {training && <TextField
          id="standard-basic" 
          label="Training Price in Rupees"
          variant="standard"
          style={{marginBottom:'10px'}}
          value={trainingPrice}
          onChange={(e)=> setTrainingPrice(e.target.value)}
        />}
        <TextField
          id="standard-basic" 
          label="Course Outcomes"
          variant="standard"
          style={{marginBottom:'10px'}}
          value={outcomes}
          onChange={(e)=> setOutcomes(e.target.value)}
        />
        <TextField
          id="standard-basic" 
          label="What To Learn ?"
          variant="standard"
          style={{marginBottom:'10px'}}
          value={learn}
          onChange={(e)=> setLearn(e.target.value)}
        />
        <TextField
          id="standard-basic" 
          label="Course Materials"
          variant="standard"
          style={{marginBottom:'10px'}}
          value={material}
          onChange={(e)=> setMaterial(e.target.value)}
        />
        <TextField
          id="standard-basic" 
          label="For Whom"
          variant="standard"
          style={{marginBottom:'10px'}}
          value={fw}
          onChange={(e)=> setFw(e.target.value)}
        />
        <Divider/>
        <List style={{padding: '0'}}>
        {modules.map((m,index) => (
          <ListItem
          key={m.title}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            backgroundColor: '#F0F0F0',
            fontSize: '15px',
            fontWeight: 'bold',
            
          }}
          onClick={(e)=>{setModuleId(m._id); setModuleF(true)}}
        >
          
          <ListItemButton
            onClick={() =>{
              setModule(index);
              setOpenHeader((prevState) => (prevState === index ? null : index));
              fetchVideos();
              fetchPdfs();
            }}
            style={{width: '100%'}}
          >
            {openHeader === index ? (
              <KeyboardDoubleArrowUpIcon />
            ) : (
              <KeyboardDoubleArrowDownIcon />
            )}
            <ListItemText className='list-text' primary={`${m.title}`} />
            {" "}{m.priority && `Priority: ${m.priority}`}
            <Button onClick={()=>{
                        setOpenM(true);
                        setModuleId(m._id)
                        axios.get(GET_SINGLE_MODULE+m._id).then(res=> setP(res.data.data?.priority))}}>
                       Set Priority
                    </Button>
                    <UpdateModuleDialog titl={m.title} desc={m.description} id={m._id}/>
          </ListItemButton>
          
               
          <Collapse in={ fetched && openHeader === index}>
            <List component='div' disablePadding style={{display:'flex' , flexDirection:'column' ,justifyContent:'center'}}>
              {videos && videos.length>0 &&  videos.map((video,index)=>{
                return( <div style={{display:'flex' , flexDirection:'row', alignItems:'center', justifyContent:'space-evenly'}}><Button
                    variant='text'
                    startIcon={<OndemandVideoIcon style={{ color: '#000' }} />}
                    style={{ width: '100%', color: '#6A58C6',height: '100%' }}
                    onClick={(e)=> {
                      setVideoUrl(video.link);
                      setOpenVideo(true); 
                    }}
                  >
                    <ListItemText primary={video.title} />
                    {" "}{video.priority && `Priority: ${video.priority}`}
                  </Button>
                  <Button onClick={()=>{
                        setOp(true);
                        setVideoId(video._id)
                        axios.get(GET_SINGLE_VIDEO+video._id).then(res=> setPriority(res.data.data.priority))}}>
                       Set Priority
                    </Button>
                  <Tooltip title="Delete">
                    <IconButton onClick = {(e) => {
                      handleDeleteVideo(video._id);
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <TextField
                  id="outlined-select-currency"
                  select
                  label="Select"
                  helperText="Please Select The Module To Change"
                  onChange={(e) => handleModuleChange(e,video._id)}
                  value={
                    video.moduleId
                  }>
                  {moduleSelect.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                  </div>)}
                  )
                }
                {pdfs && pdfs.map((pdf, index) => (
                  <div style={{display:'flex' , flexDirection:'row', alignItems:'center', justifyContent:'space-evenly'}}>
                    <Button
                      variant="text"
                      startIcon={<PictureAsPdfIcon style={{ color: '#000' }} />}
                      style={{ width: '100%', color: '#6A58C6' }}
                      onClick={() => handleOpenPDF(pdf.link)}
                      key={index}
                    >
                      <ListItemText primary={pdf.title} />
                      {" "}{pdf.priority && `Priority: ${pdf.priority}`}
                    </Button>
                    <Button onClick={()=>{
                        setOp(true);
                        setPdfId(pdf._id)
                        axios.get(GET_SINGLE_PDF+pdf._id).then(res=> setPriority(res.data.data.priority))}}>
                       Set Priority
                    </Button>
                    <Tooltip title="Delete">
                    <IconButton onClick = {(e) => {
                      handleDeletePdf(pdf._id);
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <TextField
                  id="outlined-select-currency"
                  select
                  label="Select"
                  helperText="Please Select The Module To Change"
                  onChange={(e)=>handleModuleChangePDF(e,pdf._id)}
                  value={pdf.moduleId}
                >
                  {moduleSelect.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                  </div>
                  ))}
                  {questions && questions.map((question, index) => (
                  <div style={{display:'flex' , flexDirection:'row'}}>
                    <Button
                      variant="text"
                      startIcon={<AssignmentIcon style={{ color: '#000' }} />}
                      style={{ width: '100%', color: '#6A58C6' }}
                      onClick={() => handleOpenQuestion(question.questions)}
                      key={index}
                    >
                      <ListItemText primary={question.title} />
                    </Button>
                    <Tooltip title="Delete">
                    <IconButton onClick = {(e) => {
                      handleDeleteQuestions(question._id);
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  </div>
                  ))}
                <Button
                  variant='text'
                  style={{ backgroundColor: '#F0F0F0', width: '100%', margin: '0', color: '#6A58C6' }}
                  startIcon={<AddIcon style={{ color: '#000' }} />}
                >
                  <ModuleUploadDialog courseIndex={courseIndex} moduleIndex = {index} moduleId = {m._id}/>
                </Button>
                <Button onClick={()=>{ 
                    // setModuleId(m._id);
                    handleDeleteModule(m._id);
                  } } >Delete</Button>
            </List>
          </Collapse>
          </ListItem>
          
        ))}
        </List>
        <Button variant='text' style={{backgroundColor:'#6A58C6' , width: '100%' , marginBottom:'10px'}} startIcon={ <AddCircleIcon style={{color:'#fff'}}/>}>
         <UploadDialog onAddCourse={handleAddCourseData} index={courseIndex} id={courseId} onAdd={handleRenderAdd} courseNew = {courseNew} btn={btn} setAdded={setAdded}/>
        </Button>
        <Button 
        variant='text' 
        style={{backgroundColor:'#6A58C6' , width: '100%' , margin:'0', color:'#fff'}} 
        startIcon={ <CloudQueueIcon style={{color:'#fff'}}/>}
        onClick={handleSave}
        >
          Save Course
        </Button>
        
      </Drawer>
      <Dialog open={op} onClose={handleCloseDialog}>
       <DialogTitle>Priority</DialogTitle>
        <DialogContent>
        <TextField 
          type='Number' 
          label='Priority' 
          value={priority} 
          onChange={(e) =>{ setPriority(e.target.value);setP(e.target.value)}}
          />
       </DialogContent>
        <DialogActions> 
          <Button onClick={handleCloseDialog}>Close</Button>
          <Button onClick={handleSetPriority}>Submit</Button>

        </DialogActions>
      </Dialog>
      <Dialog open={openM} onClose={handleCloseDialogM}>
       <DialogTitle>Priority</DialogTitle>
        <DialogContent>
        <TextField 
          type='Number' 
          label='Priority' 
          value={pr} 
          onChange={(e) =>{setP(e.target.value)}}
          />
       </DialogContent>
        <DialogActions> 
          <Button onClick={handleCloseDialogM}>Close</Button>
          <Button onClick={handleSetPriorityM}>Submit</Button>
        </DialogActions>
      </Dialog>
       <Modal
        open={openPDF}
        onClose={()=> setOpenPDF(false)}
        aria-labelledby="pdf-modal-title"
        aria-describedby="pdf-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Button onClick={() => setOpenPDF(false)}>Close</Button>
          <embed src={pdfUrl} width="800" height="600" type="application/pdf" />
        </Box>
      </Modal>
      <Modal
        open={openQuestions}
        onClose={()=> setOpenQuestions(false)}
        aria-labelledby="pdf-modal-title"
        aria-describedby="pdf-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' , background:'#fff' , padding:'30px' }}>
          <Button onClick={() => setOpenQuestions(false)}>Close</Button>
          {qa && qa.map((q,i) => (
            <>
            <p>Q{i+1}.{q}</p>
            <TextField 
              id="standard-basic" 
          label="Answer"
          variant="standard"
          style={{marginBottom:'10px'}}
          // value={outcomes}
          // onChange={(e)=> setOutcomes(e.target.value)}
            />
            </>
          ))}
        </Box>
      </Modal>
      <Modal
  open={openVideo}
  onClose={() => setOpenVideo(false)}
  aria-labelledby="video-modal-title"
  aria-describedby="video-modal-description"
>
  <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
    <Button onClick={() => setOpenVideo(false)}>Close</Button>
    <VideoPlayer videoUrl={videoUrl} />
    
  </Box>
</Modal>
      <MessagePopup
        open={openPopUp}
        message={resp}
        handleAlertClose={() => setOpenPopUp(!openPopUp)}
      />
    </Box>
    
  );
}


export default SideDrawer;
