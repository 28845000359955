import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Container, Card, CardContent, Grid, CircularProgress } from '@mui/material';
import axios from 'axios';
import { ORG_FETCH } from '../../api/org';

const OrgCardList = () => {
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch organizations from the backend
  useEffect(() => {
    const fetchOrgs = () => {
      axios
        .get(ORG_FETCH)
        .then((response) => {
          const orgDetails = response.data.data.map((org) => ({
            id: org.org._id, // Use organization's ID
            name: org.org.name,
            orgCode: org.org.orgCode,
            totalAppearingStudents: org.org.totalAppearingStudents || 'N/A', // Fallback if field is missing
            submitted: org.count.submitted,
            sent: org.count.sent,
            email: org.org.email
          }));

          console.log(response.data.data);
          setOrgs(orgDetails);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching organizations:', error);
          setLoading(false);
        });
    };

    fetchOrgs();
  }, []);

  // Handle card click
  const handleCardClick = (id, org) => {
    navigate(`/orglist/${id}`, { state: { org } });
  };
  
  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Organization List
        </Typography>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={3}>
            {orgs.map((org) => (
              <Grid item xs={12} sm={6} md={3} key={org.id}>
                <Card
                  onClick={() => handleCardClick(org.id , org)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: 6, // Elevates on hover
                    },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {org.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>ORG ID:</strong> {org.orgCode}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Enrolled Students:</strong> {org.totalAppearingStudents}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Submitted: </strong> {org.submitted}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <strong>Reviewed & Sent:</strong> {org.sent}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default OrgCardList;
