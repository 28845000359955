import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers';
import { TextField, Button, Box, Grid, Switch, FormControlLabel, CircularProgress, Typography, MenuItem, Select } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import axios from 'axios';
import { POST_AVAILABILTIES, PUT_AVAILABILTIES, GET_AVAILABILTIES } from '../../api/availability';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

const Availability = () => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(dayjs().startOf('day'));
  const [selectedEndTime, setSelectedEndTime] = useState(dayjs().startOf('day').add(1, 'hour'));
  const [slotLength, setSlotLength] = useState(30); // Default slot length to 30 mins
  const [slots, setSlots] = useState([]);
  const [availabilityList, setAvailabilityList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isSpanMode, setIsSpanMode] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAvailability();
    setRefresh(false);
  }, [refresh]);

  const fetchAvailability = () => {
    axios.get(GET_AVAILABILTIES)
      .then(response => {
        setAvailabilityList(response.data.reverse());
      })
      .catch(error => {
        console.error('Error fetching availability:', error);
      });
  };

  const generateSlots = (startTime, endTime) => {
    const slots = [];
    let currentTime = dayjs(startTime);

    while (currentTime.isBefore(endTime)) {
      slots.push({
        time: currentTime.format('hh:mm A'),
        isBooked: false,
      });
      currentTime = currentTime.add(slotLength, 'minute'); // Use the selected slot length
    }

    return slots;
  };

  const handleAddSlot = () => {
    const newSlots = generateSlots(selectedStartTime, selectedEndTime);
    setSlots([...slots, ...newSlots]);
  };

  const handleSubmit = async () => {
    setLoading(true); // Start loading

    if (isSpanMode && selectedStartDate && selectedEndDate) {
      let currentDate = dayjs(selectedStartDate);
      const endDate = dayjs(selectedEndDate);

      while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
        const availabilityData = {
          date: currentDate.format('YYYY-MM-DD'),
          slots: generateSlots(selectedStartTime, selectedEndTime),
        };

        try {
          const response = await axios.post(POST_AVAILABILTIES, availabilityData);
          console.log('Availability saved for date:', currentDate.format('YYYY-MM-DD'), response.data);
        } catch (error) {
          console.error('Error saving availability:', error);
        }

        currentDate = currentDate.add(1, 'day'); // Increment by one day
      }

      setSlots([]); // Clear the slots after successful save
      setRefresh(true);
    } else {
      const availabilityData = {
        date: selectedStartDate.format('YYYY-MM-DD'),
        slots,
      };

      if (editMode) {
        try {
          const response = await axios.put(`${PUT_AVAILABILTIES}${editId}`, availabilityData);
          console.log('Availability updated:', response.data);
          fetchAvailability();
          setEditMode(false);
          setSlots([]); // Clear slots after successful update
          setRefresh(true);
        } catch (error) {
          console.error('Error updating availability:', error);
        }
      } else {
        try {
          const response = await axios.post(POST_AVAILABILTIES, availabilityData);
          console.log('Availability saved:', response.data);
          fetchAvailability();
          setSlots([]); // Clear slots after successful save
          setRefresh(true);
        } catch (error) {
          console.error('Error saving availability:', error);
        }
      }
    }

    setLoading(false); // End loading
  };

  const handleEditSlot = (id) => {
    const slotToEdit = availabilityList.find((slot) => slot._id === id);
    if (slotToEdit) {
      setSelectedStartDate(dayjs(slotToEdit.date));
      setSelectedEndDate(dayjs(slotToEdit.date));
      setSlots(slotToEdit.slots);
      setEditId(id);
      setEditMode(true);
    }
  };

  const handleDeleteSlot = (id) => {
    axios.delete(`${PUT_AVAILABILTIES}${id}`)
      .then(() => {
        console.log('Availability deleted');
        fetchAvailability();
        setRefresh(true);
      })
      .catch(error => {
        console.error('Error deleting availability:', error);
      });
  };

  const columns = [
    { field: 'date', headerName: 'Date', width: 150 },
    {
      field: 'slots',
      headerName: 'Slots',
      width: 1200,
      renderCell: (params) => params.value.map((slot) => slot.time).join(', '),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <>
          <Button onClick={() => handleEditSlot(params.row.id)} variant="contained" size="small">
            Edit
          </Button>
          <Button onClick={() => handleDeleteSlot(params.row.id)} variant="contained" color="error" size="small" sx={{ marginLeft: 1 }}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  const rows = availabilityList.map((item) => ({
    id: item._id,
    date: item.date,
    slots: item.slots,
  }));

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Switch
              checked={isSpanMode}
              onChange={(e) => setIsSpanMode(e.target.checked)}
              color="primary"
            />
          }
          label="Span Mode"
        />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography textAlign={'center'}>{isSpanMode ? 'Start Date' : 'Date'}</Typography>
            <DateCalendar
              label="Start Date"
              value={selectedStartDate}
              onChange={(newDate) => setSelectedStartDate(newDate)}
            />
          </Grid>
          {isSpanMode && (
            <Grid item xs={12} sm={6}>
              <Typography textAlign={'center'}>End Date</Typography>
              <DateCalendar
                label="End Date"
                value={selectedEndDate}
                onChange={(newDate) => setSelectedEndDate(newDate)}
                minDate={selectedStartDate}
              />
            </Grid>
          )}
        </Grid>
        
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DesktopTimePicker
              label="Start Time"
              value={selectedStartTime}
              onChange={setSelectedStartTime}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DesktopTimePicker
              label="End Time"
              value={selectedEndTime}
              onChange={setSelectedEndTime}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2 }}>
          <Typography>Select Slot Length (minutes)</Typography>
          <Select
            value={slotLength}
            onChange={(e) => setSlotLength(e.target.value)}
            displayEmpty
            variant="outlined"
            sx={{ minWidth: 120, marginTop: 1 }}
          >
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={60}>60</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </Box>

        <Button variant="contained" onClick={handleAddSlot} sx={{ marginTop: 2 }}>
          Add Slot
        </Button>

        <Button variant="contained" onClick={handleSubmit} sx={{ marginTop: 2 }}>
          {editMode ? 'Update Availability' : 'Submit Availability'}
        </Button>

        <Box sx={{ marginTop: 2, p: 2 , height: '100vh' , width: '100%'}}>
          <Typography variant="h6" textAlign={'center'}>Availability List</Typography>
          <DataGrid rows={rows} columns={columns} />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default Availability;
